// extracted by mini-css-extract-plugin
export var big = "Home-module--big--dedfd";
export var el = "Home-module--el--4ccb8";
export var filter = "Home-module--filter--6b751";
export var filterOn = "Home-module--filter--on--afee5";
export var graph = "Home-module--graph--07014";
export var heading = "Home-module--heading--a5e6d";
export var heading2 = "Home-module--heading2--9872f";
export var huge = "Home-module--huge--e416e";
export var list = "Home-module--list--77c53";
export var listShow = "Home-module--list--show--c1ec8";
export var note = "Home-module--note--a1b34";
export var number = "Home-module--number--c56d2";
export var small = "Home-module--small--1aa7f";
export var sort = "Home-module--sort--6a1a0";
export var stat = "Home-module--stat--0efb9";
export var strong = "Home-module--strong--ce2f7";
export var suffix = "Home-module--suffix--e58f7";